import { GET_WHITE_LABELLING_DETAILS } from '../constant';

const INIT_STATE = {
  isSubDomain: false,
  firm: {},
  fund: null,
};

const WhiteLabelling = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WHITE_LABELLING_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export default WhiteLabelling;
