import React, { useEffect, useMemo, useState } from 'react';
import { Router, matchPath } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import mixpanel from 'mixpanel-browser';
import { useDispatch } from 'react-redux';

import { history, store } from './redux/store';
import axiosUtil from './lib/axios';
import { getWhiteLabelling } from './redux/actions/WhiteLabelling';
import configure from './constants/config';
import setTheme from './helpers/layout/setTheme';
import { FrontEndSiteUrlToStageMap } from './constants/redirects';
import { useCurrentUserInfo } from 'hooks/data/user/useCurrentUserInfo';
import { CommonRoutes, CustomRoutes } from './routes';
import { initLogrocket, initSentry, identifySentry } from 'lib/tracking';

import './App.css';
import './components/index.css';
import 'helpers/pdf/fonts';
import ContentLoader from './components/ContentLoader';
import useUser from 'hooks/ui/useUser';
import useIsSubdomain from 'hooks/ui/useIsSubdomain';

const PortalLayout = React.lazy(() => import('./layouts/Portal'));
const RawLayout = React.lazy(() => import('./layouts/Raw'));

axiosUtil.initalise(store);

const addCookieBanner = () => {
  const script = document.createElement('script');
  script.id = 'cookieyes';
  script.src =
    'https://cdn-cookieyes.com/client_data/f503be260df045342a516f16/script.js';
  document.body.appendChild(script);
};

function LoadingFallback() {
  return <ContentLoader />;
}

function App() {
  const user = useUser();
  const isSubDomain = useIsSubdomain();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useCurrentUserInfo();

  let routes = [];

  const domain = useMemo(() => {
    const url = new URL(window.location.href);
    return url.hostname.split('.');
  }, [window.location.href]);

  useEffect(() => {
    if (configure.STAGE === 'production') {
      // redirect from https://joinfurther.com to https://www.joinfurther.com
      // because user cookies/localstorage will only exist on one domain so this
      // ensures that users always stay logged in regardless of what URL they
      // load. E.g. They may follow urls from further emails that are non-www.
      // Ideally this would happen on the web-server level (ie. cloudfront atm)
      if (window.location.host.startsWith('joinfurther.com')) {
        window.location.host = 'www.joinfurther.com';
      }
      mixpanel.init('6c462f59e373c8adc607d57788fac511', { debug: true });
    }
  }, []);

  useEffect(() => {
    identifySentry(user);
    initLogrocket(user);
  }, [user]);

  useEffect(() => {
    initSentry();
    initLogrocket();
  }, []);

  const addCustomFavicons = (firm) => {
    const link = document.createElement('link');
    link.id = 'favicon';
    link.rel = 'icon';
    const appleTouch = document.createElement('link');
    appleTouch.id = 'apple-touch-icon';
    appleTouch.rel = 'apple-touch-icon';

    if (firm && firm?.investmentFirmLogo) {
      const v = new Date().toISOString();
      link.href = firm.investmentFirmLogo + `?v=${v}`;
      appleTouch.href = firm.investmentFirmLogo + `?v=${v}`;
    } else {
      link.href = 'assets/images/favicon-icon.ico';
      appleTouch.href = 'assets/images/apple-touch.png';
    }
    document.head.appendChild(link);
    document.head.appendChild(appleTouch);
  };

  const performAdviserSubdomainRedirect = () => {
    setTheme(false);
    setLoading(false);
    if (window.location.pathname === '/') {
      window.open(
        `${FrontEndSiteUrlToStageMap[configure.STAGE]}/adviser-entry`,
        '_self',
      );
    }
  };

  useEffect(() => {
    const getWhiteLabellingDetails = async () => {
      //@ts-expect-error actions file is not written in TS
      const firm = await dispatch(getWhiteLabelling(domain[0]));

      if (firm) {
        // the firm exists, setup the theme and favicons
        addCustomFavicons(firm);
        setTheme(true, firm?.whiteLabelConfig);
        setLoading(false);
      } else {
        // the firm doesn't exist, so redirect to the green site
        // unless investor site is running through ngrok to allow Browserless to access localhost
        if (domain[1] === 'ngrok-free') {
          setLoading(false);
        } else {
          window.open(FrontEndSiteUrlToStageMap[configure.STAGE], '_self');
        }
      }
    };

    if (domain?.length) {
      switch (domain[0]) {
        case configure.STAGE:
        case 'localhost':
          setTheme(false);
          setLoading(false);
          break;
        case 'www':
          setTheme(false);
          setLoading(false);
          if (configure.STAGE === 'production') {
            addCookieBanner();
          }
          break;
        case 'adviser':
          performAdviserSubdomainRedirect();
          break;
        default:
          // a subdomain has been provided - now check if it is valid for a firm
          getWhiteLabellingDetails();
          break;
      }
    }
  }, []);

  if (isSubDomain) {
    routes = CommonRoutes.filter((rec) =>
      rec.hideForSubDomains ? false : true,
    );
  } else {
    routes = CommonRoutes.filter((rec) =>
      rec.onlyAllowedInSubDomain ? false : true,
    );
  }

  const isCustomRoute = CustomRoutes.some(({ path, exact }) =>
    matchPath(location?.pathname, { path, exact }),
  );

  const Layout = isCustomRoute ? RawLayout : PortalLayout;

  return (
    //@ts-expect-error abc
    <ConnectedRouter history={history}>
      <Router history={history}>
        <React.Suspense fallback={<LoadingFallback />}>
          {loading ? <LoadingFallback /> : <Layout routes={routes} />}
        </React.Suspense>
      </Router>
    </ConnectedRouter>
  );
}

export default App;
