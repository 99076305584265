import axios from 'axios';
import { isEmpty } from 'lodash';

import { SET_USER_INFORMATION, RESET_INVESTOR } from '../constant';

import { resetAdviserInvite } from './Adviser';
import { updateWhiteLabelFund } from './WhiteLabelling';

export const inviteInvestorLogin = (value) => {
  return (dispatch) => {
    return axios
      .post(`user/invite-investor-login`, value)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem('token', response?.data?.data?.token);
          dispatch(userInformation(response?.data?.data));
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data.responseMsg });
      });
  };
};

export const getAddress = (value) => {
  return () => {
    return axios
      .get(`investor/get-uk-address`, { params: { input: value } })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data.responseMsg });
      });
  };
};

export const logout = ({ queryClient }) => {
  return (dispatch) => {
    return axios
      .get(`logout`)
      .then((response) => {
        if (response.status === 200) {
          localStorage.clear();
          dispatch({
            type: RESET_INVESTOR,
          });
          dispatch(userInformation(null));
          dispatch(resetAdviserInvite());
          dispatch(updateWhiteLabelFund());
          if (queryClient) {
            queryClient.clear();
          }
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response?.data?.responseMsg });
      });
  };
};

export const userInformation = (value) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_INFORMATION,
      payload: value,
    });
  };
};

export const getQuestionarries = () => {
  return () => {
    return axios
      .get(`investor/question-list`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data.responseMsg });
      });
  };
};

export const completeInvestment = (abandonmentId, user) => {
  return (dispatch) => {
    return axios
      .get(`user/complete-investment/${abandonmentId}`)
      .then((response) => {
        if (response.status === 200) {
          if (!isEmpty(response?.data?.data?.existingInvestor)) {
            dispatch(
              userInformation({
                ...user,
                ...response?.data?.data?.existingInvestor,
                token: user?.token,
              }),
            );
          }
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data.responseMsg });
      });
  };
};

export const findExistingUserInformation = (value) => {
  return () => {
    return axios
      .post(`user/find-existing-user`, value)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data.responseMsg });
      });
  };
};

export const submitWithdrawalRequest = (body) => {
  return () => {
    return axios
      .post(`investor/withdrawal-request`, body)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error.response.data.responseMsg);
      });
  };
};
