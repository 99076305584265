import axios from 'axios';

import { GET_WHITE_LABELLING_DETAILS } from '../constant';

export const getWhiteLabelling = (domain) => {
  return (dispatch) => {
    return axios
      .get(`investor/white-label-config?domain=${domain}`)
      .then((response) => {
        if (response?.status === 200 && response?.data?.data?.firm) {
          dispatch({
            type: GET_WHITE_LABELLING_DETAILS,
            payload: {
              firm: response?.data?.data?.firm,
              isSubDomain: true,
            },
          });
        } else {
          dispatch({
            type: GET_WHITE_LABELLING_DETAILS,
            payload: { firm: null, isSubDomain: false },
          });
        }
        return Promise.resolve(response?.data?.data?.firm);
      })
      .catch((error) => {
        return Promise.resolve({ error: error?.response?.data.responseMsg });
      });
  };
};

export const updateWhiteLabelFund = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_WHITE_LABELLING_DETAILS,
      payload: { fund: data },
    });
  };
};
