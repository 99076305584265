import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import User from './User';
import Investment from './Investment';
import WhiteLabelling from './WhiteLabelling';
import Adviser from './Adviser';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    InvestmentReducer: Investment,
    User,
    WhiteLabelling,
    AdviserInviteReducer: Adviser,
  });

export default reducers;
