const setTheme = (
  isSubDomain: boolean,
  whiteLabelConfig?: {
    primaryColor: string;
    secondaryColor: string;
    bgColor: string;
    primaryHoverColor: string;
  },
) => {
  // whiteLabelConfig can be undefined if accessed via browserless/ngrok
  if (isSubDomain && whiteLabelConfig) {
    const { primaryColor, secondaryColor, bgColor, primaryHoverColor } =
      whiteLabelConfig;
    document.documentElement.style.setProperty('--primary-color', bgColor);
    document.documentElement.style.setProperty(
      '--button-text-color',
      primaryColor,
    );
    document.documentElement.style.setProperty(
      '--button-text-hover-color',
      primaryHoverColor,
    );
    document.documentElement.style.setProperty(
      '--account-icon-color',
      secondaryColor,
    );
    document.documentElement.style.setProperty('--primary-card-color', bgColor);
  } else {
    document.documentElement.style.setProperty('--primary-color', '#56b26c');
    document.documentElement.style.setProperty(
      '--button-text-color',
      '#56b26c',
    );
    document.documentElement.style.setProperty(
      '--button-text-hover-color',
      '#468d56',
    );
    document.documentElement.style.setProperty(
      '--account-icon-color',
      '#56b26c',
    );
    document.documentElement.style.setProperty(
      '--primary-card-color',
      '#f0e9e6',
    );
  }
};
export default setTheme;
