import { useSelector } from 'react-redux';
import { store } from '@redux/store';

const useIsSubdomain = () => {
  return useSelector<ReturnType<typeof store.getState>, boolean>(
    ({ WhiteLabelling }) => WhiteLabelling.isSubDomain,
  );
};

export default useIsSubdomain;
